<template>
    <!-- loadingReportItems -->
    <v-menu
      transition='slide-y-transition'
    >
      <template v-slot:activator='{ props: menu }'>
        <v-tooltip location='left'>
            <template v-slot:activator='{ props: tooltip }'>
                <v-btn 
                    stacked
                    class='px-0'
                    v-bind='mergeProps(menu, tooltip)'
                >
                    <v-badge
                        v-if='this.items.length'
                        @click='openReportsModal'
                        color='error'
                        :content='this.items.length'
                    >
                        <v-icon>{{notificationIcon}}</v-icon>
                    </v-badge>
                    <v-icon v-else @click='openReportsModal'>{{notificationIcon}}</v-icon>
                </v-btn>
            </template>
            <!-- <span>Reports</span> -->
        </v-tooltip>
      </template>
      <v-list  
        v-if='loadingReportItems' 
        elevation='10' 
        style=' width: 400px'
      >
        <v-list-item
          disabled
          style='height: 60px;'
        >
            <v-skeleton-loader type='list-item-avatar'/>
        </v-list-item>
        
      </v-list>
      

      <v-list 
        v-else-if='items.length' 
        style='max-height: 300px; width: 400px'
        elevation='10'  
      >
        <v-list-item
          v-for='(item, i) in items'
          :key='i'
          :value='item.id'
          :disabled='isDownloading(item._id) || item.status === "error"'
          :class='`d-flex align-items-center justify-space-between cursor-${getCursor(item)}`'
          @click.stop='handleRowClick(item)'
          style='height: 60px;'
        > 
          <div class='d-flex align-center'>
            <v-icon 
                class='opacity-60 mr-3'
            >
                   {{ getIcon(item)}}
            </v-icon>
            <div>

                <v-list-item-title>{{ item.type }}</v-list-item-title>
                <v-list-item-subtitle
                    class='mb-1 text-high-emphasis opacity-70'
                >{{ getFileName(item) }}</v-list-item-subtitle>
            </div>

          </div>
          <template v-slot:append>
          <v-list-item-action class='flex-column align-end'>
            <small class='mb-4 text-high-emphasis opacity-60'
              >{{ item.updatedAt.split('T')[0] }}</small
            >
            <small v-if='isDownloading(item._id)'>
                <v-progress-circular 
                    indeterminate 
                    :size='20'
                    :width='3'
                />
            </small>
          </v-list-item-action>
        </template>
        </v-list-item>
      </v-list>
      <v-list v-else>
        <v-list-item
          style='height: 60px; width: 400px'

          class='d-flex align-items-center justify-space-between'
        >
            No reports ready yet
        </v-list-item>
      </v-list>
    </v-menu>
</template>

<script>
    import { mergeProps } from 'vue';

    export default {
        props: {
            items: {
                type: Array,
                default: () => [],
            },
            loadingReportItems: {
                type: Boolean,
                default: () => false,
            },
        },
        data() {
            return {
                notificationIcon: 'mdi-bell-outline',
                dot: false,
                numberOfReports: 0,
            };
        },
        methods: {
            mergeProps,
            openReportsModal() {         
                this.$emit('getLastReportsById');
            },
            getFileName(item) {
                if (item.fileName) return item.fileName;
                if (item.status == 'error') return 'couldn\'t generate report.';
                
                return item.remarks;
            },
            isDownloading(_id) {
                const currentItem = this.items.find(data => data._id == _id);
                return currentItem.isDownloading;
            },
            async handleRowClick(item) {
                if (item.status == 'error' && !item.fileName) return;
                item.isDownloading = true;

                if (item.fileName) {
                    // download logic
                    const { data } = await this.$batchClient.downloadFile({
                        id: item._id, 
                        type: 'reportsDump',
                        tenantId: this.$store.getters.tenantId,
                    });
                    this.downloadCSV(item.fileName , data);
                    
                    // delete the file after download
                    this.$batchClient.deleteFile({
                        _id: item._id,
                        type: 'reportsDump',
                    });                    
                } else {

                    // delete logic
                    await this.$apiClient.deleteReportById(item._id);
                }

                this.$emit('updateList', item._id);
            },
            downloadCSV(filename, csvContent) {
                const blob = new Blob([csvContent], { type: 'text/csv' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = filename;
                link.click();
            },
            getIcon(item) {
                switch (item.status){
                    case('finished'):
                        if (item.fileName) return 'mdi-download';
                        return 'mdi-close';
                    case('error'):
                        return 'mdi-minus-circle-outline';
                    default:
                        return 'mdi-minus-circle-outline';
                }
            },
            getCursor(item) {
                switch (item.status){
                    case('finished'):
                        if (item.isDownloading) return 'wait';
                        if (item.fileName) return 'pointer';
                        return 'auto';
                    case('error'):
                        // TODO: curently, VUETIFY won't do this
                        // as the row is disabled, this class is not enitiated
                        return 'not-allowed';
                    default:
                        return 'auto';
                }
            },
        },
        
    };
</script>