<template>
    <div>
        <v-app-bar app dense color='primary' dark :clipped-left='true'>
            <v-app-bar-nav-icon @click='toggleDrawer' v-if='isAuthenticated && !$route.path.includes("team-select")' />
            <v-toolbar-title style='cursor: default'> Fincom AML Platform </v-toolbar-title>
            <div class='flex-grow-1'></div>
            <div class='mx-3 d-flex align-center' style='cursor: default'>
                <NotificationsMenu 
                    v-if='isAuthenticated && showNotificationBell'
                    :items='reportItems.filter(item => item.status !== "init")'
                    :loadingReportItems='loadingReportItems'
                    @getLastReportsById='getLastReportsById'
                    @updateList='updateList'
                />
                <v-icon class='mx-2'>{{ roleIcon }}</v-icon>
                <span>
                    {{ displayedRole }}
                </span> 
            </div>
            <v-chip variant='outlined' v-if='userName' prepend-icon='mdi-account'>
                {{ userName }}
            </v-chip>
            <v-icon v-if='userRole' @click='doLogout' size='30' class='mx-3'>mdi-logout</v-icon>
        </v-app-bar>
        <MainDrawer 
            v-if='isAuthenticated && userRole && !$route.path.includes("team-select")'
            :isDrawerOpen='isDrawerOpen' 
            @updateToggle='updateToggle'
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { AUTH_LOGOUT } from '@/store/actions/auth';
import { REPORTS_INDICATION } from '@/store/actions/users';
import MainDrawer from '@/components/menu/MainDrawer';
import ActionRoles from '@/helpers/actionRoles';
import NotificationsMenu from './NotificationsMenu.vue';
import { rolesObject } from '@/helpers/roles';
import { SOLUTIONS_KEY } from '@/helpers/localStorageLabels';
import { SOLUTIONS } from '@/helpers/dataLabels';

export default {
    components: {
        MainDrawer,
        NotificationsMenu,
    },
    data() {
        return {
            isDrawerOpen: false,
            reportItems: [],
            loadingReportItems: true,
        };
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'reportsIndication', 'userName', 'userRole']),
        roleIcon(){
          return ActionRoles.adminRoles.includes(this.userRole) ? 'mdi-shield-account' : 'mdi-account-circle';
        },
        displayedRole() {
            if(!this.userRole) return '';
            return ActionRoles.bsaRoles.includes(this.userRole) ? this.userRole :  String(this.userRole[0]) + String(this.userRole).slice(1).toLowerCase();
        },
        showNotificationBell() {
            const userSolutions = localStorage.getItem(SOLUTIONS_KEY);
            const approvedRoles = [rolesObject.SYS_ADMIN, rolesObject.CHIEF_SUPERVISOR];
            return approvedRoles.includes(this.userRole) && userSolutions.includes(SOLUTIONS.pkyc);
        },
    },
    watch: {
        async isAuthenticated(newValue) {
            if (!newValue) {
                this.$router.go({ name: 'login' });
            } else {
                await this.getLastReportsById();
            }
        },
        reportsIndication: {
            deep: true,
            async handler(newValue) {                
                this.numberOfReports = Object.keys(newValue).length;
                await this.getLastReportsById();
            },
        },
    },
    methods: {
        ...mapActions({ logout: AUTH_LOGOUT, handleReport: REPORTS_INDICATION }),
        toggleDrawer() {
            this.isDrawerOpen = !this.isDrawerOpen;
        }, updateToggle(value) {
            this.isDrawerOpen = value;
        },
        async doLogout() {
            await this.logout({token: '', isForce: false, messageForLogout: 'Manual logout'});
            this.$router.go({ name: 'login' });
        },
        async getLastReportsById() {
            if (!this.showNotificationBell) return;
            try {
                this.loadingReportItems = true;
                const response = await this.$apiClient.getLatestReports();
                this.reportItems = response.data?.resultData || [];               
            } catch (e) {  
                console.log(e);                        
                this.$toast.open({
                    message: 'Couldn\'t retrieve latest reports',
                    type: 'error',
                    position: 'bottom',
                });
            } finally {
                this.loadingReportItems = false;
            }
        },
        updateList(deletedReportId) {
            this.reportItems = this.reportItems.filter(item => item._id !== deletedReportId);
        },
    },
    created () {
        if (!this.reportItems.length) {
            this.getLastReportsById();
        }
    },
};
</script>
