import supervisionClient from '@/services/supervisionClient';
import apiClient from '@/services/apiClient';

import { USERS_REQUEST, TEAMS_REQUEST, REPORTS_INDICATION } from '../actions/users';
import cacheServiceClient from '@/services/cacheServiceClient';
import {TENANT_ID_KEY, USER_ROLE_KEY} from '@/helpers/localStorageLabels';
import {DASH_DASH_EMPTY_VALUE, noAnalysts, PKYC_TYPES, SOLUTIONS} from '@/helpers/dataLabels';
import {rolesObject} from '@/helpers/roles';
import ActionRoles from '@/helpers/actionRoles';

const state = {
  analystsOnline: [],
  analystsAll: [],
  supervisors: [],
  roles: ActionRoles.roles,
  teams: [],
  reportsIndication: {
    [`${SOLUTIONS.pkyc}-${PKYC_TYPES.customers}`]: false,
    [`${SOLUTIONS.pkyc}-${PKYC_TYPES.vendors}`]: false,
    [`${SOLUTIONS.pkyc}-${PKYC_TYPES.employees}`]: false,
  },
};

const getters = {
  roleList: (state) => state.roles,
  analystsOnline: (state) => state.analystsOnline,
  analystsAll: (state) => state.analystsAll,
  supervisors: (state) => state.supervisors,
  teams: (state) => state.teams,
  reportsIndication: (state) => state.reportsIndication,
};

const actions = {
  [USERS_REQUEST]: async ({ commit }, userId) => {
    const userRole = localStorage.getItem(USER_ROLE_KEY);
    const tenantId = localStorage.getItem(TENANT_ID_KEY);

    //Get all analysts for current tenant
    //And transform to default interface [{username: ..., _id:...},{}]        
    if (!state.analystsAll.length) {
      
      const analystsResponse = await supervisionClient.getWorkers(tenantId);
    
      const allAnalystsArray = analystsResponse.data.map(analyst => ({
        username: analyst.username,
        _id: analyst._id,
        solutions: analyst.solutions,
      }));
  
      allAnalystsArray.push({username: noAnalysts, _id: DASH_DASH_EMPTY_VALUE, solutions: Object.values(SOLUTIONS)});
      commit({ type: 'setAnalystsAll', data: allAnalystsArray });  
    }
    
    let response = {};
    if (userRole === rolesObject.SUPERVISOR) {
      response = await apiClient.getSessionsByTenantId(tenantId);
      const filteredList = response.data.resultData.filter((session) => session.user.managerId === userId).map((session) => session.user);
      commit({ type: 'setAnalystsOnline', data: filteredList });
    } else {
      response = await supervisionClient.getSupervisors(userId);

      const usersSessions = await apiClient.getSessionsByTenantId(tenantId);

      const filteredList = usersSessions.data.resultData.filter(session => session.user.role === rolesObject.ANALYST).map((session) => session.user);

      // for (const supervisor of response.data) {

      //   const currentSupervisorAnalysts = usersSessions.data.resultData.filter((session) => session.user.managerId === supervisor._id).map((session) => session.user);
      //   filteredList.push(...currentSupervisorAnalysts);
      // }
      commit({ type: 'setAnalystsOnline', data: filteredList });
      commit({ type: 'setSupervisors', data: response.data.resultData });
    }
  },

  [TEAMS_REQUEST]: async ({ commit }) => {
    const tenantId = localStorage.getItem(TENANT_ID_KEY);

    const teamsDataResponse = await apiClient.getTeamsData(tenantId);

    commit({ type: 'setTeams', data: teamsDataResponse.data.resultData });
  },
  [REPORTS_INDICATION]: ({ commit }, { type, action }) => {
    const data =  { type, action };
    commit({ type: 'setReportIndication', data});
  },
};

const mutations = {
  setSupervisors(state, { data }) {
    state.supervisors = data;
  },
  setTeams(state, { data }) {
    state.teams = data;
  },
  setAnalystsOnline(state, { data }) {
    state.analystsOnline = data;
  },
  setAnalystsAll(state, { data }) {
    state.analystsAll = data;
  },
  setReportIndication: (state, { data }) => {
    const { action, type } = data;
    if (type == 'all' && action == 'remove') {
      for (const key of Object.keys(state.reportsIndication)) {
        state.reportsIndication[key] = false;    
      }
    }
    else if (action == 'add') state.reportsIndication[type] = true; 
    else state.reportsIndication[type] = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
